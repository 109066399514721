import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Image from 'gatsby-image/withIEPolyfill'
import React, { useContext, useEffect, useState } from 'react'

import GallerySection from '../../components/CarryAll/GallerySection'
import Layout from '../../components/Layout'
import PersonliseModal from '../../components/Personalisation'
import Reviews from '../../components/Reviews'
import SelectorSection from '../../components/SelectorSection'
import SetModal from '../../components/SetModal/new'
import SEO from '../../components/seo'
import StoreContext from '../../context/StoreContext'
import fbq from '../../helper/fpq'
import useLocalisation from '../../helper/useLocalisation'
import usePersonalisationParams from '../../helper/usePersonalisationParams'
import useProductData from '../../helper/useProductData'
import useSelectedColor from '../../helper/useSelectedColor'
import useTracking from '../../helper/useTracking'
import CarryOnPanels from './CarryOnPanels'
import PetPersonliseModal from '../../components/PersonalisationDog'


function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const LandingContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  @media (min-width: 763px) {
    flex-direction: row;
    padding: 64px 96px;
    padding-top: 64px;
    margin-top: 114px;
  }
`

const colorNamesText = ['Sand', 'Grey', 'Green', 'Black', 'Red', 'CBlue']
const urlColorNames = [
  'light-sand',
  'slate-grey',
  'deep-green',
  'cherry-red',
  'matte-black',
  'coastal-blue'
]
const count = ['Campaign', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const skus = []

const CarryOn = ({ data, pageContext, location }) => {
  const context = useContext(StoreContext)
  let { currency, setScroll, addVariantToCart, openCart } = context
  let [personaliseOpen, setPersonaliseOpen] = useState(undefined)
  let [collectionOpen, setCollectionOpen] = useState(false)
  let [labelData, setLabelData] = useState({})

  let [petLabelData, setPetLabelData] = useState({})
  let [petPersonaliseOpen, setPetPersonalisationOpen] = useState(undefined)
  const petPersonalised = isEmpty(petLabelData)

  const personalised = isEmpty(labelData)

  let {
    title,
    price,
    hasPersonalisation,
    freePersonalisation,
    description,
    overview,
    specs,
    reviewId,
    shopifyIds,
    fbIDs,
    oosData,
  } = useProductData(data.allCockpitProducts, !(!personalised || !petPersonalised))

  const [selectedColor, setSelectedColor] = useSelectedColor(
    location.search,
    0,
    urlColorNames.map((val) => val.toLowerCase()),
    oosData
  )

  usePersonalisationParams(location.search, setLabelData, setPersonaliseOpen)
  
  const [trackAddToCart, updateItem] = useTracking({
    title: 'Carry On',
    id: fbIDs[selectedColor] ? fbIDs[selectedColor].split('_')[2] : '',
    imageUrl: '',
    url: 'https://july.com/luggage/carry-on',
    price: parseInt((price?.match(/\d+/g) || []).join('')),
    sku: skus[selectedColor],
  })

  const images_names = colorNamesText.map((color_val) =>
    count
      .map((count_val) => {
        if (
          ['Campaign'].includes(count_val) &&
          ['Red', 'Black'].includes(color_val)
        )
          return undefined
        return `${color_val}${count_val}`
      })
      .filter((val) => val !== undefined)
  )
  const images = images_names.map((second) => [
    ...second.map((name) => data[name]),
    data.Internal0,
    data.Internal1,
    data.Internal2,
    data.Internal3,
    data.Internal4,
    data.Internal5,
    data.Internal6,
    data.Internal7,
  ])

  if (selectedColor === 4) {
    let priceNumber = parseInt((price?.match(/\d+/g) || []).join(''))
    price = price.replace(`${priceNumber}`, `${priceNumber + 50}`)
  }

  useEffect(() => {
    setScroll(!personaliseOpen)
    if (personaliseOpen === false && window.innerWidth < 900) {
      setTimeout(() => {
        const titleDiv = document.getElementById('selector-top')
        const style = getComputedStyle(titleDiv)
        window.scrollTo(0, titleDiv.offsetTop - parseInt(style.marginTop))
      }, 10)
    }
  }, [personaliseOpen, labelData, setScroll])

  useEffect(() => {
    updateItem({
      title: 'Carry On Trunk',
      id: fbIDs[selectedColor] ? fbIDs[selectedColor].split('_')[2] : '',
      price: parseInt((price?.match(/\d+/g) || []).join('')),
      url: 'https://july.com/luggage/checked-trunk',
      imageUrl: '',
      sku: skus[selectedColor],
      fb_id: fbIDs[selectedColor],
      variant: colorNamesText[selectedColor],
    })
  }, [selectedColor, fbIDs])

  const handleAddToCart = async (e) => {
    e.preventDefault()

    let carryOnVarients = shopifyIds
    let backendLabelData = {}

    if (!personalised) {
      backendLabelData = { ...labelData, font: labelData.font.toLowerCase() }
    }

    if (!petPersonalised) {
      backendLabelData = { 
        position: petLabelData.position,
        pets: btoa(JSON.stringify(petLabelData.pets.map(pet => {
          return {
            name: pet.name,
            signedUrl: pet.signedUrl
          }
        })))
      }
    }


    await addVariantToCart(carryOnVarients[selectedColor], 1, {
      ...backendLabelData,
    })
    fbq(currency, 'AddToCart', {
      content_name: title,
      content_category: 'Luggage',
      content_ids: [fbIDs[selectedColor]],
      content_type: 'product',
      value: parseInt((price?.match(/\d+/g) || []).join('')),
      currency: context.language.replace('root', 'au').toUpperCase() + 'D',
    })
    trackAddToCart()
    openCart()
  }

  const Q = useLocalisation()

  return (
    <>
      <SEO
        title={data.SEO?.title?.value}
        description={data.SEO?.description?.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.fixed?.srcWebp
        }
        productData={{
          name: title,
          imageSrc:
            'https://cdn.shopify.com/s/files/1/0047/1639/6613/products/July_Carry_On_Luggage_Navy_COPB01-NAV_01_1024x1024.png?v=155943915',
          description: data.SEO.description?.value,
          price: price,
          sku: 'COPB01-NAV',
          handle: data.SEO.cannonical_url.value,
        }}
      />
      <div
        style={{
          display: personaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {personaliseOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999999,
            }}
          >
            <PersonliseModal
              handleSave={(data) => {
                setPersonaliseOpen(false)
                setLabelData(data)
              }}
              handleClose={() => setPersonaliseOpen(false)}
              initialValues={labelData}
              selectedCaseColor={selectedColor}
              setSelectedCaseColor={setSelectedColor}
              selectedCase="trunk-checked"
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: petPersonaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {petPersonaliseOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999999,
            }}
          >
            <PetPersonliseModal
              handleSave={(data) => {
                setPetPersonalisationOpen(false)
                setPetLabelData(data)
              }}
              handleClose={() => setPetPersonalisationOpen(false)}
              initialValues={petLabelData}
              selectedCaseColor={selectedColor}
              setSelectedCaseColor={setSelectedColor}
              selectedCase="trunk-checked"
            />
          </div>
        )}
      </div>

      <div style={{ display: collectionOpen ? 'block' : 'none' }}>
        <SetModal
          handle="/luggage/checked-trunk"
          handleClose={() => {
            setCollectionOpen(false)
          }}
          labelData={labelData}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      </div>
      <div style={{ display: collectionOpen ? 'none' : 'block' }}>
        <Layout
          facebookCaseId={fbIDs[selectedColor]}
          location="product"
          context={context}
        >
          <LandingContainer>
            <GallerySection
              selectedColor={selectedColor}
              images={images[selectedColor]}
              videos={[
                {
                  video: 'trunk.mp4',
                  thumbnail: data.video_thumb,
                },
                {
                  video: 'ecom_videos/checked_trunk.mp4',
                  thumbnail: data.video_thumb2,
                },
              ]}
              campaign={true}
              defaultImage={0}
              campaignText={undefined}
              hasPersonalisation={true}
            />
            <SelectorSection
              name={title}
              price={price}
              reviewId={reviewId}
              freePersonalisation={freePersonalisation}
              oosData={oosData}
              outline={description}
              outlineSub={overview}
              details={specs}
              handleAddToCart={handleAddToCart}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              handleBefore={() => {}}
              lifetime={true}
              isLuggage={true}
              trunk={true}
              persDisabled={true}
              setCollectionOpen={setCollectionOpen}
              setPersonaliseOpen={setPersonaliseOpen}
              labelData={labelData}
              personalised={personalised}

              setPetPersonalisationOpen={setPetPersonalisationOpen}
              petLabelData={petLabelData}
              petPersonalised={petPersonalised}

            />
          </LandingContainer>
          <CarryOnPanels
            carry={false}
            setPersonaliseOpen={setPersonaliseOpen}
            collectionOpen={collectionOpen}
          />
          <Reviews productId={reviewId}></Reviews>
        </Layout>
      </div>
    </>
  )
}
export const query = graphql`
  query {
    SEO: cockpitPage(
      cannonical_url: { value: { eq: "/luggage/checked-trunk" } }
    ) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }

      TEST_banner_image {
        value {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

    allCockpitProducts(
      filter: { handle: { value: { eq: "/luggage/checked-trunk" } } }
    ) {
      edges {
        node {
          handle {
            value
          }
          title {
            value
          }
          price {
            value
          }
          price_personalised {
            value
          }
          published {
            value
          }
          has_personalisation {
            value
          }
          free_personalisation {
            value
          }
          description {
            value
          }
          overview {
            value
          }
          specs {
            value
          }
          review_id {
            value
          }
          shopify_ids {
            value {
              data
            }
          }
          fb_ids {
            value {
              data
            }
          }
          lang
          oosDetails {
            value {
              data
            }
          }
        }
      }
    }

    video_thumb: file(relativePath: { eq: "trunk/trunk.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    video_thumb2: file(relativePath: { eq: "ecom_videos/checked_trunk.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Internal0: file(relativePath: { eq: "trunk/checked/interior/0.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Internal1: file(relativePath: { eq: "trunk/checked/interior/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Internal2: file(relativePath: { eq: "trunk/checked/interior/2.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Internal3: file(relativePath: { eq: "trunk/checked/interior/3.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Internal4: file(relativePath: { eq: "trunk/checked/interior/4.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Internal5: file(relativePath: { eq: "trunk/checked/interior/5.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Internal6: file(relativePath: { eq: "trunk/checked/interior/6.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Internal7: file(relativePath: { eq: "trunk/checked/interior/7.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    GreenCampaign: file(
      relativePath: { eq: "trunk/campaign/checked/green.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    GreyCampaign: file(
      relativePath: { eq: "trunk/campaign/checked/grey.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SandCampaign: file(
      relativePath: { eq: "trunk/campaign/checked/sand.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    RedCampaign: file(relativePath: { eq: "trunk/campaign/checked/grey.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    BlackCampaign: file(
      relativePath: { eq: "trunk/campaign/checked/sand.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlueCampaign: file(
      relativePath: { eq: "trunk/campaign/checked/sand.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green0: file(relativePath: { eq: "trunk/checked/green/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey0: file(relativePath: { eq: "trunk/checked/grey/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand0: file(relativePath: { eq: "trunk/checked/sand/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red0: file(relativePath: { eq: "trunk/checked/red/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black0: file(relativePath: { eq: "trunk/checked/bla/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue0: file(relativePath: { eq: "trunk/checked/cblu/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green1: file(relativePath: { eq: "trunk/checked/green/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey1: file(relativePath: { eq: "trunk/checked/grey/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand1: file(relativePath: { eq: "trunk/checked/sand/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red1: file(relativePath: { eq: "trunk/checked/red/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black1: file(relativePath: { eq: "trunk/checked/bla/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue1: file(relativePath: { eq: "trunk/checked/cblu/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green2: file(relativePath: { eq: "trunk/checked/green/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey2: file(relativePath: { eq: "trunk/checked/grey/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand2: file(relativePath: { eq: "trunk/checked/sand/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red2: file(relativePath: { eq: "trunk/checked/red/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black2: file(relativePath: { eq: "trunk/checked/bla/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue2: file(relativePath: { eq: "trunk/checked/cblu/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green3: file(relativePath: { eq: "trunk/checked/green/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey3: file(relativePath: { eq: "trunk/checked/grey/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand3: file(relativePath: { eq: "trunk/checked/sand/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red3: file(relativePath: { eq: "trunk/checked/red/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black3: file(relativePath: { eq: "trunk/checked/bla/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue3: file(relativePath: { eq: "trunk/checked/cblu/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green4: file(relativePath: { eq: "trunk/checked/green/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey4: file(relativePath: { eq: "trunk/checked/grey/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand4: file(relativePath: { eq: "trunk/checked/sand/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red4: file(relativePath: { eq: "trunk/checked/red/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black4: file(relativePath: { eq: "trunk/checked/bla/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue4: file(relativePath: { eq: "trunk/checked/cblu/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green5: file(relativePath: { eq: "trunk/checked/green/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey5: file(relativePath: { eq: "trunk/checked/grey/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand5: file(relativePath: { eq: "trunk/checked/sand/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red5: file(relativePath: { eq: "trunk/checked/red/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black5: file(relativePath: { eq: "trunk/checked/bla/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue5: file(relativePath: { eq: "trunk/checked/cblu/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green6: file(relativePath: { eq: "trunk/checked/green/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey6: file(relativePath: { eq: "trunk/checked/grey/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand6: file(relativePath: { eq: "trunk/checked/sand/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red6: file(relativePath: { eq: "trunk/checked/red/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black6: file(relativePath: { eq: "trunk/checked/bla/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue6: file(relativePath: { eq: "trunk/checked/cblu/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green7: file(relativePath: { eq: "trunk/checked/green/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey7: file(relativePath: { eq: "trunk/checked/grey/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand7: file(relativePath: { eq: "trunk/checked/sand/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red7: file(relativePath: { eq: "trunk/checked/red/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black7: file(relativePath: { eq: "trunk/checked/bla/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue7: file(relativePath: { eq: "trunk/checked/cblu/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green8: file(relativePath: { eq: "trunk/checked/green/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey8: file(relativePath: { eq: "trunk/checked/grey/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand8: file(relativePath: { eq: "trunk/checked/sand/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red8: file(relativePath: { eq: "trunk/checked/red/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black8: file(relativePath: { eq: "trunk/checked/bla/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue8: file(relativePath: { eq: "trunk/checked/cblu/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Green9: file(relativePath: { eq: "trunk/checked/green/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Grey9: file(relativePath: { eq: "trunk/checked/grey/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand9: file(relativePath: { eq: "trunk/checked/sand/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Red9: file(relativePath: { eq: "trunk/checked/red/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black9: file(relativePath: { eq: "trunk/checked/bla/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CBlue9: file(relativePath: { eq: "trunk/checked/cblu/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default CarryOn
